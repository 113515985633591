import React from "react";
import {createMemoryHistory} from "history";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
// tunr to loadable for ssr issues
import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';

const Home = React.lazy(() =>
  import("./Home/Home.jsx")
)

let hist = createMemoryHistory();

export default function App() {
  if(typeof window !== 'undefined') {
    return (
      <React.Suspense fallback={<div />}>
      <Router history={hist}>
          <Routes>
              <Route path="/" element={<Home />}></Route>
          </Routes>
        </Router>
        </React.Suspense>
    );
  } else {
    return "<div>Loading...</div>";
  }
};